import { Elm } from './src/Main.elm';
import './scss/styles.scss';
import { initialise } from '../../common/Elm/bootstrapElm';

const { bootstrap, mount, unmount } = initialise([{
    elmModule: Elm.Main,
    domGetter: () => document.getElementById('app-currentbookings'),
    flags: { },
}]);

export {
    bootstrap,
    mount,
    unmount,
};
